// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
const spec_Identifier = {__proto__:null,where:14, true:26, false:28, null:32, and:46, or:48, not:54, in:58, limit:72, order:78, by:80, asc:86, desc:88, select:92, as:96, render:100, each:102, all:104}
export const parser = LRParser.deserialize({
  version: 14,
  states: "-OOYQPOOOpQQO'#C_QOQPOOOzQQO'#DROOQO'#Dc'#DcO#OQQO,58yOzQQO'#CaOzQQO'#DPO#YQQO'#DSO#_QQO'#DZO$cQQO'#D_O$nQQO'#CmOOQO'#Ce'#CeO&SQSO'#DVOOQO'#Cp'#CpOzQQO'#CqO&vQPO'#C}OzQQO'#CvO(fQSO'#CdOOQO'#Cd'#CdO)cQQO'#EWOOQO,59m,59mOOQO'#Ch'#ChOOQO-E7a-E7aO)}QSO,58{O*hQSO,59kOOQO,59n,59nO+RQSO'#D]O+YQSO'#CdO+vQQO'#EXOOQO,59u,59uOOQO,59y,59yO,bQPO,59yO,gQSO'#DlO,tQPO,59XOOQO,59X,59XO,yQQO,59ZOzQQO,59dOzQQO,59dOzQQO,59dOzQQO,59dOzQQO,59fOOQO,59q,59qOzQQO,59^OzQQO,59^OOQO'#DW'#DWO-OQSO,59]O-VQPO,59aO-[QPO'#DOO-aQPO'#EVO-iQPO,59iOOQO,59i,59iO-nQSO,59bO/wQQO,59gOzQQO'#DfO0OQQO,5:rO0jQQO,59wO#_QQO'#DgO0oQQO,5:sOOQO1G/e1G/eOzQQO'#DdO1ZQPO,5:WOOQO1G.s1G.sOOQO1G.u1G.uO3iQSO1G/OO5sQSO1G/OO6QQSO1G/OO7cQSO1G/OO8iQSO1G/QO:vQSO1G.xO:}QSO1G.xOOQO1G.w1G.wOOQO1G.{1G.{OzQQO,59jO;UQPO'#DeO;ZQPO,5:qOOQO1G/T1G/TO;cQPO1G/ROOQO1G/R1G/ROOQO,5:Q,5:QOOQO-E7d-E7dOOQO1G/c1G/cOOQO,5:R,5:ROOQO-E7e-E7eO;hQSO,5:OOOQO-E7b-E7bOzQQO7+$lO;uQSO1G/UOOQO,5:P,5:POOQO-E7c-E7cOOQO7+$m7+$mO<PQSO<<HW",
  stateData: ">]~O!^OSPOS~OSPO~OVUOtVOwWO!OXO!SYO~O![RX!gRX~P_OUbOY[OZ[O]fO^fO_[O`[ObcOkaOpcO!_ZO!c^O!d_O!f`O!haO!iaO~O![Ra!gRa~P_OxjO~OUlOY[OZ[O]fO^fO_[O`[ObcOkaOpcO!_ZO!c^O!d_O!f`O!haO!iaO~OpoO!TpO!UpO~O!bsO~PzOg{Oh|OmuO!ctO!iwO!juO!kuO!luO!muO!nuO!ouO!puO!quO!rvO!svO!tvO!uwO!vxO!wyO~O{}O|}OVyXtyXwyX!OyX!SyX![yX!ayX!gyX~P$uOSPOZ!QO!g!TO~O!d!VOgWXhWXmWX!cWX!iWX!jWX!kWX!lWX!mWX!nWX!oWX!pWX!qWX!rWX!sWX!tWX!uWX!vWX!wWX!QWX~OVWXtWXwWX{WX|WX!OWX!SWX![WX!aWX!bWX!eWX!xWX!gWX~P'RO!a!WOV!zXt!zXw!zX!O!zX!S!zX![!zX!g!zX~OVTatTawTa!OTa!STa![Ta!gTa~P$uOVsatsawsa!Osa!Ssa![sa!gsa~P$uO!Q!YO~P$uOV!PXt!PXw!PX!O!PX!S!PX![!PX!a!PX!g!PX~P'RO!a!ZOV!{Xt!{Xw!{X!O!{X!S!{X![!{X!g!{X~Op!]O~O!a!^O!b!`X!e!`X~P$uO!b!`O~OU!aO~O!e!iO~P$uO!g!jO~O!x!kO~O!a!lO!g!yX~O!g!nO~O!ctOVjagjahjamjatjawja{ja|ja!Oja!Sja![ja!aja!ija!jja!kja!lja!mja!nja!oja!pja!qja!rja!sja!tja!uja!vja!wja!Qja!bja!eja!xja!gja~O!e!pO~PzO!a!WOV!zat!zaw!za!O!za!S!za![!za!g!za~OU!sO~O!a!ZOV!{at!{aw!{a!O!{a!S!{a![!{a!g!{a~O!a!^O!b!`a!e!`a~O!ctO!rvO!svO!tvOVliglihlimlitliwli{li|li!Oli!Sli![li!ali!jli!kli!lli!mli!nli!oli!pli!qli!vli!wli!Qli!bli!eli!xli!gli~O!iwO!uwO~P1fO!ctOVliglihlimlitliwli{li|li!Oli!Sli![li!ali!ili!jli!kli!lli!mli!nli!oli!pli!qli!uli!vli!wli!Qli!bli!eli!xli!gli~O!rli!sli!tli~P3sO!ili!uli~P1fOg{OmuO!ctO!iwO!juO!kuO!luO!muO!nuO!ouO!puO!quO!rvO!svO!tvO!uwO!wyO~OVlihlitliwli{li|li!Oli!Sli![li!ali!vli!Qli!bli!eli!xli!gli~P6[O!x!xO~P$uOmuO!ctO!iwO!juO!kuO!luO!muO!nuO!ouO!puO!quO!rvO!svO!tvO!uwO!wyOVfihfitfiwfi{fi|fi!Ofi!Sfi![fi!afi!vfi!Qfi!bfi!efi!xfi!gfi~Ogfi~P8pOg{O~P8pOZ!QO~O!a!lO!g!ya~O!e!|O~O!a!Wa!b!Wa!e!Wa~P$uO!ari!gri~P$uOmuO!ctO!iwO!juO!kuO!luO!muO!nuO!ouO!puO!quO!rvO!svO!tvO!uwOVnygnyhnytnywny{ny|ny!Ony!Sny![ny!any!vny!wny!Qny!bny!eny!xny!gny~OUY~",
  goto: "&w!|PPP!}P#TPP#X$aPP$wPPPP$wP$a$a$a$aPP$a$aP$aP$a$aP$a%_#TP#T%ePP%i%oPP#TP%rP#TPPP%x&O&U&[&bPPPP&hPPPPPPPPPPPPPPPPPPPPPPPP&n&q&tQQOR!P`TSPTS]R!WQhUQiVSkX!ZSqZ!VQ!O_Q!UaQ!buQ!cvQ!dwQ!exQ!fyQ!g{Q!h|Q!v!^Q!y!kR!}!xycRUVXZ_auvwxy{|!V!W!Z!^!k!xy[RUVXZ_auvwxy{|!V!W!Z!^!k!xQ!R`R!z!lTRPTQdRR!q!WRz]QmXR!t!ZQTPRgTQ!_qR!w!_Q!m!RR!{!mQ!XdR!r!XQ![mR!u![QrZR!o!VR!S`ReRRnX",
  nodeNames: "⚠ LineComment Program Query TagIdentifier WhereClause Identifier where Expression Value Number String Bool true false Regex null List GlobalIdentifier Attribute TopLevelVal ParenthesizedExpression LogicalExpression and or QueryExpression UnaryExpression not BinExpression in TernaryExpression Call PageRef Object KeyVal LimitClause limit OrderClause Order order by OrderBy OrderDirection asc desc SelectClause select Select as RenderClause render each all",
  maxTerm: 89,
  skippedNodes: [0,1],
  repeatNodeCount: 5,
  tokenData: "/R~RuX^#fpq#fqr$Zrs$pst%_uv%vwx%{xy&eyz&jz{&o{|&t|}&y}!O'O!O!P'T!P!Q'Y!Q![)q![!]*[!^!_*a!_!`*n!`!a*{!a!b+Y!b!c+g!c!},U!}#O-W#P#Q.T#S#T.Y#T#o,U#o#p.w#q#r.|#y#z#f$f$g#f#BY#BZ#f$IS$I_#f$I|$JO#f$JT$JU#f$KV$KW#f&FU&FV#f~#kY!^~X^#fpq#f#y#z#f$f$g#f#BY#BZ#f$IS$I_#f$I|$JO#f$JT$JU#f$KV$KW#f&FU&FV#fU$`P!hQ!_!`$cS$hP!mS#r#s$kS$pO!qS~$sTOr$prs%Ss;'S$p;'S;=`%X<%lO$p~%XOZ~~%[P;=`<%l$p~%dSP~OY%_Z;'S%_;'S;=`%p<%lO%_~%sP;=`<%l%_~%{O!t~~&OTOw%{wx%Sx;'S%{;'S;=`&_<%lO%{~&bP;=`<%l%{~&jO!d~~&oO!e~~&tO!r~~&yO!u~~'OO!a~~'TO!i~~'YO!c~U'_W!sSOY'wZ]'w^!P'w!Q#O'w#O#P(l#P;'S'w;'S;=`)k<%lO'wQ'zXOY'wZ]'w^!P'w!P!Q(g!Q#O'w#O#P(l#P;'S'w;'S;=`)k<%lO'wQ(lO_QQ(oRO;'S'w;'S;=`(x;=`O'wQ({YOY'wZ]'w^!P'w!P!Q(g!Q#O'w#O#P(l#P;'S'w;'S;=`)k;=`<%l'w<%lO'wQ)nP;=`<%l'w~)vQY~!O!P)|!Q![)q~*PP!Q![*S~*XPY~!Q![*S~*aO!x~~*fP!j~!_!`*i~*nO!k~~*sP!l~#r#s*v~*{O!p~~+QP!o~!_!`+T~+YO!n~~+_P!w~!a!b+b~+gO!v~~+jQ!c!}+p#T#o+p~+uTb~}!O+p!Q![+p!c!}+p#R#S+p#T#o+pV,]USPUU}!O,U!P!Q,o!Q![,U!c!},U#R#S,U#T#o,UP,tUSP}!O,o!P!Q,o!Q![,o!c!},o#R#S,o#T#o,o~-]P!_~!}#O-`~-cTO#P-`#P#Q-r#Q;'S-`;'S;=`-}<%lO-`~-uP#P#Q-x~-}Op~~.QP;=`<%l-`~.YO!b~U.]TO#S.Y#S#T.l#T;'S.Y;'S;=`.q<%lO.YU.qOUUU.tP;=`<%l.Y~.|O!f~~/RO!g~",
  tokenizers: [0, 1, 2],
  topRules: {"Program":[0,2]},
  specialized: [{term: 6, get: (value) => spec_Identifier[value] || -1}],
  tokenPrec: 1344
})
