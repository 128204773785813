// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
const spec_Identifier = {__proto__:null,true:18, false:20, null:24, and:38, or:40, where:50, limit:54, order:60, by:62, asc:68, desc:70, select:74, as:78, render:82, each:84, all:86, not:92, in:96}
export const parser = LRParser.deserialize({
  version: 14,
  states: "-OOYQPOOO!^QPO'#CiOOQO'#C`'#C`Q!eQQOOOOQO'#Cl'#ClOYQPO'#CmO#rQSO'#DaOYQPO'#DZO%bQQO'#C_OOQO'#C_'#C_OOQO'#Cc'#CcO&_QQO'#DlO&lQPO,59TOOQO,59T,59TO&qQPO,59VOYQPO,59wOYQPO,59wOYQPO,59wOYQPO,59wOYQPO,59yOYQPO,59YOYQPO,59YO&vQQO,59XO'`QPO'#CrO'gQPO,59]O'lQPO'#DbO'qQPO'#EXO'yQPO,59{OOQO,59{,59{O(OQQO,59uO*XQPO,59zOYQPO'#DcO*`QPO,5:WOOQO1G.o1G.oOOQO1G.q1G.qO,nQQO1G/cO.xQQO1G/cO/VQQO1G/cO0hQQO1G/cO1nQQO1G/eO3{QQO1G.tO4SQQO1G.tOOQO1G.s1G.sOYQPO'#CxOOQO'#Dd'#DdO4ZQPO,59^OYQPO'#CtOYQPO'#CvO4bQPO'#CyO4gQPO'#DQO5kQPO'#DUOOQO1G.w1G.wOYQPO,59|O5vQPO'#DgO5{QPO,5:sOOQO1G/g1G/gO6TQPO1G/fOOQO1G/f1G/fO6YQQO,59}OOQO-E7a-E7aOYQPO7+%PO6gQQO'#C|O7WQPO'#DsOOQO,59d,59dOOQO-E7b-E7bO7oQQO,59`O8VQQO,59bOOQO,59e,59eO8mQQO'#DSO8tQQO'#DSO9_QPO'#DtOOQO,59l,59lOOQO,59p,59pO9vQPO,59pO9{QQO1G/hOOQO,5:R,5:ROOQO-E7e-E7eOOQO7+%Q7+%QO:VQQO<<HkOOQO,59h,59hOOQO'#C}'#C}OYQPO'#DeO<`QPO,5:_O<wQPO,59nO4gQPO'#DfO<|QPO,5:`OOQO1G/[1G/[OOQO,5:P,5:POOQO-E7c-E7cOOQO1G/Y1G/YOOQO,5:Q,5:QOOQO-E7d-E7d",
  stateData: "=h~O!^OSPOS~OTQOUQOWWOXYOYYOZQO[QO^XO|XO!OVO!_PO!cSO!dTO!fUO!jVO!kVO~O!b]O~PYOcdOdeO!Q_O!c^O!kaO!l_O!m_O!n_O!o_O!p_O!q_O!r_O!s_O!t`O!u`O!v`O!waO!xbO!ycO~OUiOggO!ilO~O!dnOcRXdRX!QRX!cRX!kRX!lRX!mRX!nRX!oRX!pRX!qRX!rRX!sRX!tRX!uRX!vRX!wRX!xRX!yRXwRX~O![RX!aRX!bRX!eRX!zRXiRXkRXnRXrRXsRXuRXyRX!iRX~P#}O!aoO!b!`X!e!`X~P!eO!bqO~OWrO~O!ezO~P!eOi!OOk!POn!QOu!ROy!SO~O!ifX~P&}O!i!TO~O!z!UO~O!a!VO!i!{X~O!i!XO~O!c^Oc}ad}a!Q}a![}a!k}a!l}a!m}a!n}a!o}a!p}a!q}a!r}a!s}a!t}a!u}a!v}a!w}a!x}a!y}a!a}a!b}a!e}a!z}ai}ak}an}ar}as}au}ay}a!i}aw}a~O!e!ZO~PYO!aoO!b!`a!e!`a~O!c^O!t`O!u`O!v`Oc!Pid!Pi!Q!Pi![!Pi!l!Pi!m!Pi!n!Pi!o!Pi!p!Pi!q!Pi!r!Pi!s!Pi!x!Pi!y!Pi!a!Pi!b!Pi!e!Pi!z!Pii!Pik!Pin!Pir!Pis!Piu!Piy!Pi!i!Piw!Pi~O!kaO!waO~P*kO!c^Oc!Pid!Pi!Q!Pi![!Pi!k!Pi!l!Pi!m!Pi!n!Pi!o!Pi!p!Pi!q!Pi!r!Pi!s!Pi!w!Pi!x!Pi!y!Pi!a!Pi!b!Pi!e!Pi!z!Pii!Pik!Pin!Pir!Pis!Piu!Piy!Pi!i!Piw!Pi~O!t!Pi!u!Pi!v!Pi~P,xO!k!Pi!w!Pi~P*kOcdO!Q_O!c^O!kaO!l_O!m_O!n_O!o_O!p_O!q_O!r_O!s_O!t`O!u`O!v`O!waO!ycO~Od!Pi![!Pi!x!Pi!a!Pi!b!Pi!e!Pi!z!Pii!Pik!Pin!Pir!Pis!Piu!Piy!Pi!i!Piw!Pi~P/aO!z!^O~P!eO!Q_O!c^O!kaO!l_O!m_O!n_O!o_O!p_O!q_O!r_O!s_O!t`O!u`O!v`O!waO!ycOdbi![bi!xbi!abi!bbi!ebi!zbiibikbinbirbisbiubiybi!ibiwbi~Ocbi~P1uOcdO~P1uO!ifa~P&}Oo!eO~OTQOUQOW!gOXYOYYOZQO[QO^XO|XO!OVO!_PO!cSO!dTO!fUO!jVO!kVO~Oz!kO{!kO|!jO~OUiO~O!a!VO!i!{a~O!e!oO~O!a!Va!b!Va!e!Va~P!eOr!rOs!rOipXkpXnpXupXypX!apX!ipX~P!eO!a!sOi!gXk!gXn!gXu!gXy!gX!i!gX~Oihakhanhauhayha!iha~P!eOijakjanjaujayja!ija~P!eOw!uO~P!eOivXkvXnvXuvXyvX!avX!ivX~P#}O!a!vOi!hXk!hXn!hXu!hXy!hX!i!hX~O|!xO~O!a!Ui!i!Ui~P!eO!Q_O!c^O!kaO!l_O!m_O!n_O!o_O!p_O!q_O!r_O!s_O!t`O!u`O!v`O!waOc!Ryd!Ry![!Ry!x!Ry!y!Ry!a!Ry!b!Ry!e!Ry!z!Ryi!Ryk!Ryn!Ryr!Rys!Ryu!Ryy!Ry!i!Ryw!Ry~O!a!sOi!gak!gan!gau!gay!ga!i!ga~OW!{O~O!a!vOi!hak!han!hau!hay!ha!i!ha~OWT~",
  goto: "&y!|PPP!}$YPP$qPPPPP$qP$Y$Y$Y$YPP$Y%YP%]P%]P%]%aPP%e%kPP%]P%nP%]PPPP$YP$YP$Y$Y$Y%t%z&Q&W&^&dPPPP&jPPPPPP&p&sPPPPPPPPPPPPPPPPPP&vQROSZPnQfTQmVQs_Qt`QuaQvbQwcQxdQyeQ![oS!_{!sQ!c!OQ!d!PS!f!R!vQ!l!UR!p!^{XOPTV_`abcdeno{!O!P!R!U!^!s!v{QOPTV_`abcdeno{!O!P!R!U!^!s!vRhUT|g}T{g}Q!`{R!y!sR!q!_Q!h!RR!|!vQjUR!m!VQpZR!]pQ}gR!b}Q!t!`R!z!tQ!w!hR!}!wQ!WjR!n!WQ[PR!YnR!a{R!i!RRkU",
  nodeNames: "⚠ LineComment Program Expression Value Number String Bool Identifier true false Regex null List GlobalIdentifier Attribute TopLevelVal ParenthesizedExpression LogicalExpression and or QueryExpression Query TagIdentifier WhereClause where LimitClause limit OrderClause Order order by OrderBy OrderDirection asc desc SelectClause select Select as RenderClause render each all PageRef UnaryExpression not BinExpression in TernaryExpression Call Object KeyVal",
  maxTerm: 89,
  skippedNodes: [0,1],
  repeatNodeCount: 5,
  tokenData: "/R~RuX^#fpq#fqr$Zrs$pst%_uv%vwx%{xy&eyz&jz{&o{|&t|}&y}!O'O!O!P'T!P!Q'Y!Q![)q![!]*[!^!_*a!_!`*n!`!a*{!a!b+Y!b!c+g!c!},U!}#O-W#P#Q.T#S#T.Y#T#o,U#o#p.w#q#r.|#y#z#f$f$g#f#BY#BZ#f$IS$I_#f$I|$JO#f$JT$JU#f$KV$KW#f&FU&FV#f~#kY!^~X^#fpq#f#y#z#f$f$g#f#BY#BZ#f$IS$I_#f$I|$JO#f$JT$JU#f$KV$KW#f&FU&FV#fR$`P!jP!_!`$cQ$hP!oQ#r#s$kQ$pO!sQ~$sTOr$prs%Ss;'S$p;'S;=`%X<%lO$p~%XOU~~%[P;=`<%l$p~%dSP~OY%_Z;'S%_;'S;=`%p<%lO%_~%sP;=`<%l%_~%{O!v~~&OTOw%{wx%Sx;'S%{;'S;=`&_<%lO%{~&bP;=`<%l%{~&jO!d~~&oO!e~~&tO!t~~&yO!w~~'OO!a~~'TO!k~~'YO!c~R'_W!uQOY'wZ]'w^!P'w!Q#O'w#O#P(l#P;'S'w;'S;=`)k<%lO'wP'zXOY'wZ]'w^!P'w!P!Q(g!Q#O'w#O#P(l#P;'S'w;'S;=`)k<%lO'wP(lOZPP(oRO;'S'w;'S;=`(x;=`O'wP({YOY'wZ]'w^!P'w!P!Q(g!Q#O'w#O#P(l#P;'S'w;'S;=`)k;=`<%l'w<%lO'wP)nP;=`<%l'w~)vQT~!O!P)|!Q![)q~*PP!Q![*S~*XPT~!Q![*S~*aO!z~~*fP!l~!_!`*i~*nO!m~~*sP!n~#r#s*v~*{O!r~~+QP!q~!_!`+T~+YO!p~~+_P!y~!a!b+b~+gO!x~~+jQ!c!}+p#T#o+p~+uT^~}!O+p!Q![+p!c!}+p#R#S+p#T#o+pV,]UgSWR}!O,U!P!Q,o!Q![,U!c!},U#R#S,U#T#o,US,tUgS}!O,o!P!Q,o!Q![,o!c!},o#R#S,o#T#o,o~-]P!_~!}#O-`~-cTO#P-`#P#Q-r#Q;'S-`;'S;=`-}<%lO-`~-uP#P#Q-x~-}O|~~.QP;=`<%l-`~.YO!b~R.]TO#S.Y#S#T.l#T;'S.Y;'S;=`.q<%lO.YR.qOWRR.tP;=`<%l.Y~.|O!f~~/RO!i~",
  tokenizers: [0, 1, 2],
  topRules: {"Program":[0,2]},
  specialized: [{term: 8, get: (value) => spec_Identifier[value] || -1}],
  tokenPrec: 1309
})
